import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'gatsby';

import {toggleLang, getLang} from '../services/lang';
import Layout from '../components/Layout';
import * as TXT from '../res/strings';
import * as TXTEN from '../res/strings_en';
import { INSTAL_BAR, INSTAL_BAR_MOB } from '../res/images';

class Amenities extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: 'ES',
    }

    this.toggleLanguage = this.toggleLanguage.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount(){
    if (getLang().language !== this.state.lang) {
      this.setState({lang: getLang().language});
      }
    this.updateWindowDimensions();
  }

  toggleLanguage(lang) {
    toggleLang(lang);
    if (lang !== this.state.lang) {
      this.setState({lang: lang});
    }
  }

  updateWindowDimensions() {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  }

  render() {
    const largeImage = <img src={INSTAL_BAR} alt="Quinta-Lili Amenities" style={{ width: 1080, height: 'auto' }} />
    const smallImage = <img src={INSTAL_BAR_MOB} alt="Quinta-Lili Amenities" style={{ width: '98%', height: 'auto' }} />
    const APP_LANG = this.state.lang;
    return (
      <Layout lang={this.state.lang} toggleLanguage={this.toggleLanguage}>
        <div className="section" style={{ marginTop: 100 }}>
          <Container>
            <Row>
              <Col className="text-center title">
                <h1>
                  {APP_LANG === 'EN' ? TXTEN.AMENITIES.TITLE1 : TXT.AMENITIES.TITLE1}
                </h1>
              </Col>
            </Row>
            { this.state.windowWidth >= 1023 ? largeImage : smallImage } 
            <Row>
							<Col xs="12" md={{ size: 8, offset: 2 }} >
								<p>
									{APP_LANG === 'EN' ? TXTEN.AMENITIES.CONTENT_MAIN : TXT.AMENITIES.CONTENT_MAIN}
								</p>
							</Col>
            </Row>
            <Row>
              <Col xs="12" md={{ size: 6, offset: 3 }} className="has-text-second" >
                <ul>
                  <li>{APP_LANG === 'EN' ? TXTEN.AMENITIES.CONTENT_LISTITEM1 : TXT.AMENITIES.CONTENT_LISTITEM1}</li>
                  <br />
                  <li>{APP_LANG === 'EN' ? TXTEN.AMENITIES.CONTENT_LISTITEM2 : TXT.AMENITIES.CONTENT_LISTITEM2}</li>
                  <br />
                  <li>{APP_LANG === 'EN' ? TXTEN.AMENITIES.CONTENT_LISTITEM3 : TXT.AMENITIES.CONTENT_LISTITEM3}</li>
                  <br />
                  <li>{APP_LANG === 'EN' ? TXTEN.AMENITIES.CONTENT_LISTITEM4 : TXT.AMENITIES.CONTENT_LISTITEM4}</li>
                  <br />
                  <li>{APP_LANG === 'EN' ? TXTEN.AMENITIES.CONTENT_LISTITEM5 : TXT.AMENITIES.CONTENT_LISTITEM5}</li>
                  <br />
                  <li>{APP_LANG === 'EN' ? TXTEN.AMENITIES.CONTENT_LISTITEM6 : TXT.AMENITIES.CONTENT_LISTITEM6}</li>
                  <br />
                  <li>{APP_LANG === 'EN' ? TXTEN.AMENITIES.CONTENT_LISTITEM7 : TXT.AMENITIES.CONTENT_LISTITEM7}</li>
                  <br />
                  <li>{APP_LANG === 'EN' ? TXTEN.AMENITIES.CONTENT_LISTITEM8 : TXT.AMENITIES.CONTENT_LISTITEM8}</li>
                  <br />
                  <li>{APP_LANG === 'EN' ? TXTEN.AMENITIES.CONTENT_LISTITEM9 : TXT.AMENITIES.CONTENT_LISTITEM9}</li>
                  <br />
                  <li>{APP_LANG === 'EN' ? TXTEN.AMENITIES.CONTENT_LISTITEM10 : TXT.AMENITIES.CONTENT_LISTITEM10}</li>
                  <br />
                  <li>{APP_LANG === 'EN' ? TXTEN.AMENITIES.CONTENT_LISTITEM11 : TXT.AMENITIES.CONTENT_LISTITEM11}</li>
                  <br />
                  <li>{APP_LANG === 'EN' ? TXTEN.AMENITIES.CONTENT_LISTITEM12 : TXT.AMENITIES.CONTENT_LISTITEM12}</li>
                </ul>
              </Col>
            </Row>
            <span className="separator_100" />
						<Row>
              <Col xl="12" className="text-center">
                <Link to="/reserve">
                  <div className="btnAction mt-auto">
                    {APP_LANG === 'EN' ? TXTEN.BUTTON.CTA_RESERVE : TXT.BUTTON.CTA_RESERVE}
                  </div>
                </Link>
              </Col>
            </Row>
						<Row>
              <Col xl="12" className="text-center">
                <Link to="/">
                  <div className="btnAction mt-auto">
                    {APP_LANG === 'EN' ? TXTEN.BUTTON.BTN_BACK_HOME : TXT.BUTTON.BTN_BACK_HOME}
                  </div>
                </Link>
              </Col>
            </Row>
            <Row />
          </Container>
        </div>
      </Layout>
    );
  }

}

export default Amenities;